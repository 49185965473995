import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-74068a12"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "meal-selection"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  class: "footer"
};
import { ref, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import TopNav from '@/components/TopNav';
import { useRouter } from 'vue-router';
import StepIndicator from '@/components/StepIndicator';
import { useProductStore } from '@/stores/productStore';
import { useOrderStore } from '@/stores/orderStore';
import { useLogStore } from '@/stores/logStore';
import ProductItem from '@/components/ProductItem';
import Customization from '@/views/MealSelection/customization';
import { useAnimationStore } from '@/stores/animationStore';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const productStore = useProductStore();
    const orderStore = useOrderStore();
    const logStore = useLogStore();
    const animationStore = useAnimationStore();
    const {
      code,
      selectedItems
    } = storeToRefs(orderStore);
    const customizationVisible = ref(false);
    const loading = ref(false);
    const error = ref(null);
    const products = ref([]);
    const currentProductIndex = ref(null);
    const prevStep = () => {
      router.push('/restaurants');
    };
    const nextStep = () => {
      router.push('/confirm');
    };
    const fetchProduct = async () => {
      loading.value = true;
      error.value = null;
      try {
        await productStore.fetchProductByCode(code.value);
        const productDetail = productStore.product;
        products.value = productDetail.products;

        // 初始化 selectedItems
        products.value.forEach((product, index) => {
          product.productSelectDetail.rounds.forEach(round => {
            const maxSelectable = round.saleQty || round.items.length;
            // 使用商品索引作为键
            if (!selectedItems.value[index]) {
              selectedItems.value[index] = {};
            }
            // 检查是否已经有选中的项
            if (!selectedItems.value[index][round.id]) {
              if (round.saleQty === 1 && round.items.length === 1) {
                // 自动选择唯一选项
                selectedItems.value[index][round.id] = [round.items[0].itemSkuCode];
              } else {
                // 自动选择默认选项
                const defaultSelected = round.items.filter(item => item.isDefault === "Y").slice(0, maxSelectable).map(item => item.itemSkuCode);
                selectedItems.value[index][round.id] = defaultSelected;
              }
            }
          });
        });
      } catch (err) {
        error.value = err;
      } finally {
        loading.value = false;
      }
    };
    const openCustomization = index => {
      currentProductIndex.value = index;
      customizationVisible.value = true;
    };
    const closeCustomization = () => {
      customizationVisible.value = false;
    };
    const confirmCustomization = () => {};
    const confirmSelection = () => {
      const allSelected = products.value.every((product, index) => {
        console.log(`Checking selection for product at index: ${index}`);
        return product.productSelectDetail.rounds.every(round => {
          const maxSelectable = round.saleQty || round.items.length;

          // 根据已选的 skuCode 数量，计算实际选择的 item 数量
          const selectedSkuCodes = selectedItems.value[index]?.[round.id] || [];
          const selectedCount = round.items.filter(item => selectedSkuCodes.includes(item.itemSkuCode)).length;
          return selectedCount === maxSelectable;
        });
      });
      if (allSelected) {
        // 处理确认选择的逻辑
        const selectedProducts = products.value.map((product, index) => {
          const selectedRounds = product.productSelectDetail.rounds.map(round => {
            const selectedItemsInRound = round.items.filter(item => selectedItems.value[index]?.[round.id]?.includes(item.itemSkuCode));
            return {
              ...round,
              items: selectedItemsInRound
            };
          });
          return {
            ...product,
            productSelectDetail: {
              ...product.productSelectDetail,
              rounds: selectedRounds
            }
          };
        });
        orderStore.setProducts(selectedProducts);
        nextStep();
      } else {
        ElMessage({
          message: '请点击选规格选商品',
          type: 'error'
        });
        animationStore.triggerHighlight();
      }
    };
    onMounted(() => {
      logStore.logUserAction('用户进入兑换码商品列表页面');
      if (code.value) {
        fetchProduct();
      } else {
        console.warn('Waiting for code to be set...');
      }
    });
    watch(code, newCode => {
      if (newCode) {
        console.log('Code has been set:', newCode);
        fetchProduct();
      }
    });
    const {
      product
    } = storeToRefs(productStore);
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(TopNav), {
        title: "兑换码商品列表"
      }), _createVNode(_unref(StepIndicator), {
        activeStep: 1
      })]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_4, "加载中...")) : _createCommentVNode("", true), error.value ? (_openBlock(), _createElementBlock("div", _hoisted_5, "错误: " + _toDisplayString(error.value.message), 1)) : _createCommentVNode("", true), _unref(product) ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 2
      }, _renderList(products.value, (product, index) => {
        return _openBlock(), _createElementBlock("div", null, [_createVNode(_unref(ProductItem), {
          product: product,
          index: index,
          onOpenCustomization: openCustomization
        }, null, 8, ["product", "index"])]);
      }), 256)) : _createCommentVNode("", true)])), [[_directive_loading, loading.value]]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_button, {
        type: "success",
        class: "select-button",
        round: "",
        onClick: confirmSelection
      }, {
        default: _withCtx(() => [_createTextVNode("确认选择")]),
        _: 1
      })]), customizationVisible.value ? (_openBlock(), _createBlock(_unref(Customization), {
        key: 0,
        currentProductIndex: currentProductIndex.value,
        onConfirm: confirmCustomization,
        onClose: closeCustomization
      }, null, 8, ["currentProductIndex"])) : _createCommentVNode("", true)]);
    };
  }
};